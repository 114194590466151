<template>
  <div
    class="drager_col"
    ref="container"
    :style="{ width: width, height: height }"
  >
    <div class="drager_left" :class="{transitioning: isTransitioning}" :style="{ width: leftWidth + '%' }">
      <div>
        <slot name="left"></slot>
      </div>
    </div>
    <div
      class="slider_col"
      @touchstart.passive="mobileDragCol"
      @mousedown="dragCol"
      :style="{
        width: sliderWidth + 'px',
        marginLeft: -sliderWidth / 2 + 'px',
        marginRight: -sliderWidth / 2 + 'px',
      }"
    >
    <i class=" close-sizer bi sizer-collapse-button" title="Collapse">
        <div v-if="canCollapseLeft" class="bi-chevron-left" @click="collapse(COLLAPSED_LEFT);"></div>
        <div v-if="canCollapseRight" class="bi-chevron-right" @click="collapse(COLLAPSED_RIGHT);"></div>
    </i>
    </div>
    <div class="drager_right" :class="{transitioning: isTransitioning}" :style="{ width: 100 - leftWidth + '%' }">
      <div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'

const emit = defineEmits(['isDragging', 'dragging'])

export interface Props {
    leftPercent?: Number
    sliderWidth?: Number
    height?: Number
    width?: Number
    collapseLeft?: Boolean
    collapseRight?: Boolean
}

const COLLAPSED_LEFT = ref(-1),
      NOT_COLLAPSED = ref(0),
      COLLAPSED_RIGHT = ref(1);

const props = withDefaults(defineProps<Props>(), {
    leftPercent: 10,
    sliderWidth: 4,
    height: 400,
    width: 400,
    collapseLeft: true,
    collapseRight: true
});



const left = ref(props.leftPercent),
      isDragging = ref(false),
      container = ref(null),
      time = ref(null),
      isCollapsed = ref(NOT_COLLAPSED.value),
      isTransitioning = ref(false);

const leftWidth = computed(() => {
    switch(isCollapsed.value) {
        case NOT_COLLAPSED.value:
            return left.value;
        case COLLAPSED_LEFT.value:
            return 0;
        case COLLAPSED_RIGHT.value:
            return 100;
    }
});

const canCollapseLeft = computed(() => {
    return isCollapsed.value == COLLAPSED_RIGHT.value || (props.collapseLeft && isCollapsed.value != COLLAPSED_LEFT.value)
});
const canCollapseRight = computed(() => {
    return isCollapsed.value == COLLAPSED_LEFT.value || (props.collapseRight && isCollapsed.value != COLLAPSED_RIGHT.value)
});

function collapse(dir: int) {
    isCollapsed.value += dir;
    isTransitioning.value = true;
}

function mobileDragCol(e) {
      e = e || window.event;
      e.stopPropagation();
      let oldPos = e.changedTouches[0].clientX;
      let oldPosPercent = left.value;
      let newPos = 0;
      let newPosPercent = 0;
      const containerWidth = container.value.offsetWidth;

      isDragging.value = true;
      emit("isDragging", isDragging.value);
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
      function sliderDrag(e) {
        if (time.value && Date.now() - time.value < 40) return;
        time.value = Date.now();
        e = e || window.event;
        e.stopPropagation();
        newPos = e.changedTouches[0].clientX;
        const movingDistancePercent = parseFloat(
          (((oldPos - newPos) / containerWidth) * 100).toFixed(3)
        );
        newPosPercent = oldPosPercent - movingDistancePercent;
        if (newPosPercent <= 0) {
          left.value = 0;
        } else if (newPosPercent >= 100) {
          left.value = 100;
        } else {
          left.value = newPosPercent;
        }
        emit("dragging", left.value);
      }
      function cancelSliderDrag() {
        isDragging.value = false;
        emit("isDragging", isDragging.value);
        document.ontouchmove = null;
        document.ontouchend = null;
      }
    }

function dragCol(e) {
      e = e || window.event;
      e.preventDefault();
      e.stopPropagation();
      let oldPos = e.clientX;
      let oldPosPercent = left.value;
      let newPos = 0;
      let newPosPercent = 0;
      const containerWidth = container.value.offsetWidth;
      isDragging.value = true;
      emit("isDragging", isDragging.value);
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
      function sliderDrag(e) {
        if (time.value && Date.now() - time.value < 40) return;
        time.value = Date.now();
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        newPos = e.clientX;
        const movingDistancePercent = parseFloat(
          (((oldPos - newPos) / containerWidth) * 100).toFixed(3)
        );
        newPosPercent = oldPosPercent - movingDistancePercent;
        if (newPosPercent <= 0) {
          left.value = 0;
        } else if (newPosPercent >= 100) {
          left.value = 100;
        } else {
          left.value = newPosPercent;
        }
        emit("dragging", left.value);
      }
      function cancelSliderDrag() {
        isDragging.value = false;
        emit("isDragging", isDragging.value);
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
</script>